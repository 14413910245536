import { render, staticRenderFns } from "./ResetPasswordForm.vue?vue&type=template&id=eb3fc1fe&"
import script from "./ResetPasswordForm.vue?vue&type=script&lang=js&"
export * from "./ResetPasswordForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../frontend.kantoor.mobile/app/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports