<template>    
    <v-form @submit.prevent="'true'" ref="form">
        <v-row dense>
            <v-col>
                <TextField label="E-mail" :dense="!true"
                    prepend-inner-icon="mdi-account"
                    :rules="[v => !!v || 'E-mail is verplicht']" 
                    v-model="email" required>
                </TextField>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col>
                <PasswordField inner-icon label="Wachtwoord" :dense="!true"
                    prepend-inner-icon="mdi-lock"
                    :rules="[v => !!v || 'Wachtwoord is verplicht']" 
                    v-model="password"></PasswordField>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col>
                <PasswordField inner-icon label="Bevestig wachtwoord" :dense="!true"
                    prepend-inner-icon="mdi-lock"
                    :rules="[v => !!v || 'Wachtwoord is verplicht']" 
                    v-model="passwordConfirm"></PasswordField>
            </v-col>
        </v-row>

        <v-row dense>
            <Button type="submit" :loading="user.isLoading" block main primary large @click="onResetPassword">Reset Wachtwoord</Button>
        </v-row>
        <v-row dense>
            <v-col align="right" justify="right">
                <slot name="links">
                    <router-link to="/login" class="text-sm base-link">
                        Terug naar inloggen
                    </router-link>
                </slot>
            </v-col>
        </v-row>

    </v-form>

</template>

<script>
import user from '@app/user'
import TextField from '@controls/input/TextField'
import PasswordField from '@controls/input/PasswordField'
import Button from '@controls/buttons/ActionButton'
import eventbus from "@app/eventbus"

//
// Usage: 
//
//   import LoginForm from '@shared/ui/auth/LoginForm'
//
export default {
    name: 'ForgotPasswordForm',
    components: {
        TextField, 
        PasswordField,
        Button
    },
    data() {
        return {
            email: "",
            password: "",
            passwordConfirm: "",
        }
    },
    props: {
        col: {
            type: [String],
            default: ""
        }
    }, 
    methods: {
        onResetPassword: async function() {

            if (!this.$refs.form.validate()) {
                return false;
            }

            var result = await this.user.resetPassword(this.email, this.password, this.passwordConfirm, this.$route.query.token);
            if (!result.success) {
                eventbus.snackbar.error({text: result.message});
            } else {
                eventbus.snackbar.info({text: result.message});
            }
        },
    },
    setup() {
        return {
            user: user
        }
    }
}
</script>
